<template>
  <div class="about">
    <header class="page-header">
      <div class="container">
        <div class="page-header-flex-wrap no-margin-bottom">
          <h1 class="page-title section-header">
            {{ $t("confirm_registration") }}
          </h1>
        </div>
      </div>
    </header>
    <section class="section medium" v-show="welcome_screen">
      <div class="container">
        <div class="container-small">
          {{ $t("confirm_registration") }}
        </div>
      </div>
    </section>
    <section class="section medium" v-show="confirmed">
      <div class="container">
        <div class="container-small">
          {{ $t("confirmed_registration") }}
        </div>
      </div>
    </section>
    <section class="section medium" v-show="unconfirmed">
      <div class="container">
        <div class="container-small">
          {{ $t("unconfirmed_registration") }}
        </div>
      </div>
    </section>
    <section class="section medium" v-show="error_params">
      <div class="container">
        <div class="container-small">
          {{ $t("error_params_registration") }}
        </div>
      </div>
    </section>


  </div>
</template>

<script>
import axios from "axios";
import config from "@/store/config";
export default {
  name: "confirmRegistration",
  data() {
    return {
      welcome_screen : true,
      unconfirmed : false,
      confirmed : false,
      error_params : false
    };
  },
  mounted() {
    this.sendConfirmRegistration();
  },
  methods: {
    sendConfirmRegistration: function() {
      let parameters = this.$route.query;
      if (parameters && parameters.token) {
        axios({
          method: "POST",
          url: config.getters.mainUrl + "auth/confirmRegistration?ts=" + Math.round(+new Date() / 1000),
          data: {
            lang  : this.$cookie.get("elza_lng"),
            token : parameters.token
          },
          headers: {
            "Cache-Control": "no-cache",
            Pragma: "no-cache",
            Expires: "0",
          },
        }).then(
            result => {
              if (
                  result != undefined &&
                  result.data != undefined &&
                  result.data.result != undefined
              ) {
                if (result.data.result == true) {
                  this.confirmed = true;
                  this.welcome_screen = false;
                } else {
                  this.unconfirmed = true;
                  this.welcome_screen = false;
                }
              }
            },
            error => {
              console.log(error);
            },
        );
      } else {
        this.welcome_screen = false;
        this.error_params = true;
      }
    }
  },
}
</script>